import React from 'react';
import './License Mwali.css'

/* ~~~~ */
import VectorFour from '../../../Assets/images/vector-4.png'
import LicenseMwali_01 from '../../../Assets/images/License-Mwali-img-01.png'
import LicenseMwali_02 from '../../../Assets/images/License-Mwali-img-02.png'
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Banking License in Mwali',
        link: '/license',
    },
]

function LicenseMwali () {
    return (
        <main className='main'>
            <section
                className='banking-license-section section'
            >
                <BreadCrumbs data={BreadCrumbsData}/>
                <div className='banking-license-container container' >

                    <div className='offshore-title-container flex-start'>
                        <div className='flex-row'>
                            <img src={VectorFour} className='primary-vector' alt='vector'/>
                            <h1 className='section_title'>Banking License in Mwali</h1>
                        </div>
                    </div>

                    <div className='licensing-container grid-container '>
                        <div className='flex-start'>
                            <p className='offshore-text'>
                                As the world becomes a global village and more investors look beyond their geographical boundaries for security, strength and opportunity, there is a great business case for forming your own bank in tax-neutral environment. This international banks offer great benefits to the owners as they enjoy more privacy and ease of use than their domestic units. <br />

                                The autonomous Comoran island state of Moheli passed its Offshore Banking Act in 2001 and currently has more than eighty registered institutions. The Mwali International Services Authority (MISA) is dedicated to providing as much business freedom and flexibility as possible, along with strong statutory confidentiality for legitimate offshore banking clients, whilst maintaining a strong compliance culture in anti-money-laundering and anti-terrorist financing matters. The result is that neither Moheli nor the Comoros have ever been subject to the censure, scandal and international blacklisting that have plagued less well-regulated jurisdictions over recent years.
                            </p>
                        </div>

                        <div className='image-container' >
                            <img  src={LicenseMwali_01} alt='offshore-img'/>
                        </div>
                    </div>

                    <img className='middle-img' src={LicenseMwali_02} alt='offshore-img'/>

                    <div className='flex-start'>
                        <p className='body-pargraph'>
                            Offshore banking licenses may be approved expeditiously subject to successful background checks on directors and shareholders and approval of the business plan by the banking regulator’s office. Offshore banking licenses are granted by MISA exclusively to Mwali IBCs. If desired, the application for an IBC may be submitted simultaneously with the bank license application.
                        </p>
                        <p>
                            From 01 January 2013 has entered a new Banking Act 2013. The purpose of this Act is to foment the development of the Island as a responsible international financial center complying with global best practice and regulatory standards, and to provide incentives by way of tax exemptions for international banking business carried on from the island.
                        </p>
                        <p>
                            In preparing the application, particular attention should be paid to the business plan. The regulator will be looking to ensure that the proposed bank is sufficiently capitalized for its planned operations. Banks with the minimum permitted capital will typically be licensed solely for in-house treasury functions, whereas banks looking to accept deposits from the general public will be expected to have higher capitalization and human resources depending on their expected operations. The Moheli regulator demands high liquidity at all times.
                        </p>
                        <p>
                            Moheli Corporate Services Limited is experienced in providing a comprehensive ‘bank manager’ service, or clients may take an ‘a la carte’ approach, keeping costs low by using only the
                            services they need as business grows. Besides the basic registered agent and application processing service, available services (subject to negotiation) include:
                        </p>
                    </div>

                    <div className='flex-start'>

                        <ul className='license-list'>
                            <li>
                                Business plan preparation, in consultation with clients and their professional advisers
                            </li>
                            <li>
                                Provision of professionally qualified managers and directors
                            </li>
                            <li>
                                Development of AML policy and Compliance Manual
                            </li>
                            <li>
                                Assistance with opening correspondent accounts internationally
                            </li>
                            <li>
                                Assistance with application for SWIFT/BIC code
                            </li>
                            <li>
                                Provision of local presence, dedicated employee, phone line etc
                            </li>
                            <li>
                                Opening of representative offices in other countries
                            </li>
                            <li>
                                Provision and hosting of internet banking software
                            </li>
                            <li>
                                International credit and debit card
                            </li>
                        </ul>

                        <p className='body-pargraph'>
                            Please feel free to contact our offices to discuss requirements and processes in detail. We are looking forward to working as your trusted partner.
                        </p>

                    </div>
                </div>


            </section>
        </main>
    )
        ;
}

export default LicenseMwali;
