import React from 'react'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import verctor6 from '../../../Assets/Home/vector-6.png'
import BrokerageAndForexSeychelles_01 from '../../../Assets/images/brokerage-and-forex-seychelles-01.png'
import BrokerageAndForexSeychelles_02 from '../../../Assets/images/brokerage-and-forex-seychelles-02.png'

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Brokerage And Forex License in Seychelles',
    link: '/brokerage-and-forex-seychelles',
  },
]

function BrokerageForexSeychelles() {
  return (
    <main className='main'>
      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />
        <div className='container'>

          <div className='page-header'>
            <img src={verctor6} alt='icon' className='primary-vector'/>
            <h1>Brokerage and Forex license in Seychelles</h1>
          </div>

          <br/>
          <div className='head-section'>
            <div className='content'>
              <p className='body-pargraph'>
                If you plan to operate in financial markets and offer FX
                brokerage services to clients from regulated jurisdictions, you
                must apply for a Forex broker license, and Seychelles,
                definitely, suits well to get it. This license is required for
                all brokerage firms, mutual and hedge funds.
              </p>
              <h2 className='text-left'>Reasons for choosing an offshore jurisdiction</h2>
              <p className='body-pargraph'>
                Authorities in both offshore and onshore jurisdictions shall
                grand the license to the entity, which fulfills all statutory
                requirements, but as a rule, it is much more easier to adhere to
                offshore legal regime, it is especially important, in terms of
                minimum capital requirements. Below, the basic requirements for
                obtaining Seychelles forex license are displayed.
              </p>
              <h2 className='text-left'>
                Seychelles Financial Services Authority will not grant a license
                unless it is satisfied that the applicant:
              </h2>
              <ul className='license-list'>
                <li>
                  Is a domestic company or a company incorporated under the laws
                  of a recognized jurisdiction;
                </li>
                <li>
                  Has at least 2 (two) natural person appointed as directors,
                  residence requirements are not stipulated;
                </li>
                <li>
                  Has at least 2 (two) shareholders; legal and physical person
                  can act as a shareholder.
                </li>
                <li>
                  Employs at least one licensed Representative of Securities
                  Dealer and a Compliance officer. The directors can occupy this
                  position;
                </li>
                <li>Has a minimum paid‐up capital of US$ 50,000;</li>
                <li>
                  Satisfies the FSA that it is a fit and proper person to be
                  licensed as a dealer;
                </li>
                <li>Provides for a separate account to hold clients’ funds;</li>
                <li>Has a local office;</li>
                <li>Has Professional Indemnity Cover in place</li>
              </ul>
            </div>
            <div className='image'>
              <img src={BrokerageAndForexSeychelles_01} alt='landscape'/>
            </div>
          </div>
          <div className='vector-img'>
            <img src={BrokerageAndForexSeychelles_02} alt='due diligence'/>
          </div>
          <div className='body-content'>
            <h2 className='text-left'>The advantages of a Seychelles forex license:</h2>
            <ul className='license-list'>
              <li>
                Eligibilities of the license holder: the right to engage in
                securities trading, such as shares, options, bonds and others,
                as well as to manage investment portfolio on behalf of a client.
              </li>
              <li>Offshore tax regime.</li>
              <li>
                Seychelles is one of the most popular jurisdiction for the
                establishment of a brokerage company; all the necessary
                infrastructure for the establishment of a company and doing
                business is already present.
              </li>
            </ul>
            <h2 className='text-left'>
              Seychelles is one of the best jurisdictions for opening a company
              and obtaining a Forex brokerage license in terms of reasonable
              cost and minimal capital requirements. Prompt timeframes are,
              also, worth mentioning:
            </h2>
            <ul className='license-list'>
              <li>Establishment of a Company – 1 week;</li>
              <li>
                Preparation of all necessary documents to apply for the license
                – approx.1 month, subject to the specific clients’ needs and the
                difficulty of a project;
              </li>
              <li>
                Review of the application be the FSA – 1 months, period may be
                extended by the FSA up to 3 months
              </li>
            </ul>
            <h2 className='text-left'>Application procedure:</h2>
            <ol className='oredered-list'>
              <li>
                Data collection.
                <p>
                  This is the first stage, at which a comprehensive KYC
                  procedure takes its place, the procedure incudes:
                  verification of the identity documents, residence address, the
                  source of funds and the check whether a client possess
                  necessary education, qualifications and experience to engage
                  in this business.
                </p>
              </li>
              <li>
                Preparation of the application.
                <p>
                  At this stage, application forms to be filled and all
                  necessary documents to be collected, this, also, includes
                  drafting of the Articles and Memorandum.
                </p>
              </li>
              <li>
                Submission of the application.
                <p>
                  After all documents collected and the forms filled, we will
                  submit the application to Seychelles FSA. All government fees
                  to be paid at this stage.
                </p>
              </li>
              <li>
                Further support.
                <p>
                  Our specialists will be in touch with the FSA and monitor the
                  procedure of application consideration. Required documents
                </p>
              </li>
            </ol>
            <h2 className='text-left'>
              In accordance with the established requirements, each of the
              directors or officers, shareholders and beneficiaries must provide
              the following documents:
            </h2>
            <ol className='oredered-list'>
              <li>Police Clearance Certificate</li>
              <li>Declaration of no history of Bankruptcy</li>
              <li>Certified Passport Copy</li>
              <li>Original or Certified Copy of Bank Reference Letter</li>
              <li>2 Certified copies of Professional Reference Letters</li>
              <li>Original or Certified Copy of utility bills</li>
              <li>
                Resume / CV outlining experience in Forex Brokerage Activities
                and Qualifications warranting issuance of a license.
              </li>
              <li>
                Information on who the target audience and market will be.
              </li>
            </ol>
            <p className='body-pargraph'>
              All documents must be issued no longer than 3 months prior to the
              submission, certified and, if required, translated into English
              and apostilled.
            </p>
            <p className='body-pargraph'>
              Eternity Law International will provide you with expert advice on
              obtaining a forex broker license in a suitable jurisdiction.{' '}
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default BrokerageForexSeychelles
