import React from 'react'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import verctor6 from '../../../Assets/Home/vector-6.png'
import BrokerageAndForexCyprus_01 from '../../../Assets/images/brokerage-and-forex-cyprus-01.png'
import BrokerageAndForexCyprus_02 from '../../../Assets/images/brokerage-and-forex-cyprus-02.png'

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Brokerage And Forex License in Cyprus',
    link: '/brokerage-and-forex-cyprus',
  },
]

function BrokerageForexCyprus() {
  return (
    <main className='main'>
      <section className='section'>
        <div className='container'>
          <BreadCrumbs data={BreadCrumbsData} />
          <div className='page-header'>
            <img src={verctor6} alt='icon' className='primary-vector' />
            <h1 className='section_title'>Brokerage and Forex license in Cyprus</h1>
          </div>
          <br/>
          <div className='head-section'>
            <div className='content'>
              <p className='body-pargraph'>
                Forex-trading is making money on currency exchanging deals.
                Forex-market exists globally and its turnover exceeds millions
                of dollars. Cyprus is one of the first countries to legalize
                Forex trading and has gone to great lengths to keep traders safe
                by offering them quality, greatly-full and strict regulation.
                However, despite the severity of certain points of regulative
                framework, Forex-regulation in Cyprus is quite loyal to the
                representatives of such a niche.
              </p>
              <br />
              <p className='body-pargraph'>
                Our firm is experts in the field of companies’ licensing and
                formation in hundreds of jurisdictions around the world. We will
                become your secure allies in getting license to operate in any
                of your chosen spaces, in particular, in getting Forex broker
                license in Belize, Spain, Malta and others. With us, you do not
                have to cope with all difficulties and nuances that may arise on
                your own – we’ll take care of this completely.
              </p>
              <h2 className='text-left'>Cyprus forex license: main requirements</h2>
              <p className='body-pargraph'>
                Regulatory body controlling activities of Forex-brokers and
                directly issues permits to be evolved in this direction is
                Cyprus Securities and Exchange Commission – CySEC. Commercial
                structures that are interested in the official approval of their
                activities need to meet a number of normative points put forward
                by the regulator.
              </p>
              <br />
              <ol className='oredered-list'>
                <li>
                  The presence of registered office-premises in the
                  jurisdiction.
                </li>
                <li>
                  The presence in firm of a director with a higher financial
                  education and sufficient experience in such field.
                </li>
                <li>
                  The presence of a well-written concise description of future
                  activities.
                </li>
                <li>
                  Absolute and unconditional compliance with the conditions that
                  are associated with the initial capital. Its size is at least
                  125 thousand euros if we are talking about the STP license,
                  and 730 thousand euros if we are talking about the Market
                  Making license.
                </li>
              </ol>
            </div>
            <div className='image'>
              <img src={BrokerageAndForexCyprus_01} alt='landscape' />
            </div>
          </div>
          <div className='vector-img'>
            <img src={BrokerageAndForexCyprus_02} alt='due diligence' />
          </div>
          <div className='body-content'>
            <p className='body-pargraph'>
              As already mentioned, Cyprus is included in the list of the first
              jurisdictions where the Forex markets first began to form and
              develop. The infrastructure for the relevant firms in this country
              is developed in the best possible way, therefore brokers are
              primarily interested in a brokerage license in Cyprus. Cyprus is
              often seen as a competitor to the BVI and Mauritius. The last of
              the mentioned jurisdictions is quite popular due to the special
              loyalty and profitability of the local FSC regulator. It is
              important to note that having obtained a Forex license in Cyprus,
              the licensee must properly prepare and submit financial
              statements.
            </p>
            <p className='body-pargraph'>
              As for the package of documents required to get Cyprus Forex
              license, it consists of a list of the following papers that are
              mandatory for all beneficiaries, directors and shareholders as a
              minimum set:
            </p>
            <ul className='license-list'>
              <li>copies of internal and foreign passports;</li>
              <li>documentary evidence of residential addresses;</li>
              <li>
                letters of recommendation from a reliable banking institution to
                confirm financial status;
              </li>
              <li>professional resume;</li>
              <li>
                statements from a banking institution, which will indicate the
                presence of a sufficient amount of funds in the account.
              </li>
            </ul>
            <p className='body-pargraph'>
              In addition to the above, additional documents may also be
              requested by the regulator. Our experts will help you prepare and
              collect everything you need.
            </p>
            <h2 className='text-left'>Types of forex licenses in Cyprus</h2>
            <p className='body-pargraph'>
              Within the jurisdiction, there is one single permit for all Forex
              projects. A Cyprus forex license can be obtained by companies
              classified as CIF, which are classified as participants in the
              securities market at a professional level, but in a broader sense
              – with coverage of the forex market. After obtaining the necessary
              permission, the organization has the right to manage portfolio
              investments, engage in investment advice, receive and execute
              orders, work with investments and financial instruments.
            </p>
            <p className='body-pargraph'>
              Forex license Cyprus opens up many opportunities, in particular,
              the right to legally reside in any country that has EU membership,
              which increases the reputation and global image of the company.
              The licensed organization will be included in the list of official
              brokers.
            </p>
            <h2 className='text-left'>Advantages of forex licenses in Cyprus</h2>
            <p>
              Along with such jurisdictions as the BVI and Mauritius, this
              country also offers a lot of specific advantages. The local
              regulator, as well as the Mauritius regulator FSC, tries to
              provide maximum security and commercial comfort for Forex firms,
              while setting a loyal requirement list and forming a reliable
              regulatory framework. Forex companies get the opportunity to enjoy
              the following benefits and privileges.
            </p>
            <ol className='license-list'>
              <li>
                Membership in the EU: the legislative and regulatory framework
                of the state is developing in such a way as to be a single whole
                and form an integral part of the legislative framework of the
                European space.
              </li>
              <li>
                The right to conduct business throughout the European Union.
              </li>
              <li>
                The state puts forward a clear and transparent requirement list,
                which is aimed at ensuring and maintaining maximum comfort for a
                sense of security for businessmen.
              </li>
              <li>A clear system of control over licensed firms.</li>
              <li>
                The regulatory framework is constantly evolving, supplementing
                and improving.
              </li>
              <li>
                The issuance of brokerage and investment licenses has been
                carried out in the jurisdiction for many decades, which is the
                key to many years of experience.
              </li>
              <li>
                Qualified and specially trained employees in the labor market.
              </li>
            </ol>
            <p className='body-pargraph'>
              Our company will provide you with comprehensive professional
              assistance not only in licensing, but also in acquiring a
              ready-made company in Spain, Portugal, Germany, Poland, Estonia
              and other jurisdictions around the world. We deal with various
              commercial niches, particularly, cryptocurrency sphere, gambling,
              etc.
            </p>
            <h2 className='text-left'>Procedure to obtain a forex license in Cyprus</h2>
            <p className='body-pargraph'>
              The procedure for Forex licensing in Cyprus is quite simple and
              straightforward. It consists of several following steps.
            </p>
            <ol className='oredered-list'>
              <li>Registration of a company in the jurisdiction.</li>
              <li>
                Opening a corporate account in a bank institution, necessary for
                commercial needs.
              </li>
              <li>Introducing initial capital.</li>
              <li>Hiring staff.</li>
              <li>Rental of premises for office use.</li>
              <li>
                Preparation of the necessary package of documents for filing an
                application.
              </li>
              <li>Direct application.</li>
              <li>
                With the correct implementation of the previous paragraphs –
                getting Forex license Cyprus.
              </li>
            </ol>
            <h2 className='text-left'>Taxation of forex companies in Cyprus</h2>
            <p className='body-pargraph'>
              Speaking about the taxation of Forex structures in Cyprus, it is
              worth noting the following aspects. The jurisdiction has a low
              corporate tax of 12.5%. The 0% rate applies to income tax. The
              rate of 19% is valid for VAT – one of the lowest. There is no
              obligation to pay tax on dividends. The jurisdiction has a wide
              network of agreements concluded in order to avoid double taxation.
            </p>
            <h2 className='text-left'>What is included in our services</h2>
            <p className='body-pargraph'>
              Our company – professionals and experts in the establishment and
              licensing of companies around the world. We work to achieve the
              most effective results for our clients in the shortest possible
              time. The list of our services includes the following.
            </p>
            <ol className='oredered-list'>
              <li>Registration of firms.</li>
              <li>
                Obtaining licenses to conduct activities. We work with all
                commercial areas.
              </li>
              <li>Collection and preparation of a package of documents.</li>
              <li>Opening bank accounts and merchant accounts.</li>
              <li>Corporate Services.</li>
              <li>Sale of which firms and licenses.</li>
            </ol>
            <p className='body-pargraph'>
              We have extensive experience in this area, so contacting us, you
              can be absolutely calm.
            </p>
            <h3 className='text-left'>How much is it to obtain a forex license in Cyprus?</h3>
            <p className='body-pargraph'>
              The cost of licensing is calculated individually in each case, as
              it depends on many related factors. Our experts will help you
              correctly calculate the total cost of obtaining a permit, as well
              as optimize your budget as much as possible.
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default BrokerageForexCyprus;
