import React from 'react'
import './Footer.css'

import PrivacyPolicy from '../../Assets/pdf/Privacy Policy.pdf';
import Terms from '../../Assets/pdf/Terms of Use.pdf';


import {
  FaSkype,
  FaFacebook,
  FaLinkedin, FaTelegramPlane,
} from 'react-icons/fa'
import {IoLogoWhatsapp} from "react-icons/io";

function Footer() {

  const cyprusTelegram = '+35796782975';
  const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

  const cyprusWhatsappUrl = `https://wa.me/${cyprusTelegram.replace('+', '')}`;


  return (
      <main className='main-footer'>
        <div className='footer container'>
          <div className='links'>
            <a href={PrivacyPolicy} target='_blank'>Privacy Policy</a>
            <a href={PrivacyPolicy} target='_blank'>Terms</a>
            <a href='/'>Copyright</a>
          </div>
          <div className='social-btns'>
            <a href='https://join.skype.com/invite/AdDbThJOeejF' target='_blank'>
              <FaSkype className='secondary-icon'/>
            </a>
            <a href='https://www.facebook.com/share/vC5S5JbubCZ47WRx/?mibextid=LQQJ4d' target='_blank'>
              <FaFacebook className='secondary-icon'/>
            </a>
            <a href={cyprusTelegramUrl} className='contact-info-link' target='_blank'>
              <FaTelegramPlane className='secondary-icon'/>
            </a>
            <a href='/'>
              <FaLinkedin className='secondary-icon'/>
            </a>
            <a href={cyprusWhatsappUrl} className='contact-info-link' target='_blank'>
              <IoLogoWhatsapp className='secondary-icon'/>
            </a>
          </div>
        </div>
      </main>
  )
}

export default Footer
