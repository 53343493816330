import React from 'react';
import './home.css'
import HomeVectorOne from '../../../Assets/Home/home-vector-one.png';
import { IoRemoveOutline } from "react-icons/io5";

/* vectors ~~~~ */
import VectorSeven from '../../../Assets/Home/vector-7.png'

function Home() {
    return (
        <main className='main'>
            <section
                className='wrapper_one home-wrapper-one section'
                style={{
                    backgroundImage: `url(${HomeVectorOne})`,
                }}
            >
                <div className="hero-container">
                    <h1 className="hero-title">
                        Offshore Advisory Corporate
                        Legal Services Worldwide
                    </h1>

                    <div className='hero-rows'>
                        <div className='hero-row'>
                            <h3><strong>Cyprus</strong></h3>
                            <h3>+357 9678 2975</h3>
                        </div>
                        <div className='hero-row'>
                            <h3><strong>Albania</strong></h3>
                            <h3>+355 69 337 7716</h3>
                        </div>
                        <div className='hero-row'>
                            <h3><strong>London</strong></h3>
                            <h3>+44 20 4577 4328</h3>
                        </div>
                    </div>

                    <div className='hero-columns'>
                        <p className='hero-column'>
                            4713<br/>
                            Companies Serviced
                        </p>

                        <p className='hero-column'>
                            541<br/>
                            Licenses Obtained
                        </p>
                    </div>

                </div>
            </section>

            <section className='section'>
                <div className='hf-container container'>

                    <h1 className='special-offer-title section_title'>
                        <IoRemoveOutline />
                        {/* */}
                        Special Offer

                        <IoRemoveOutline />
                    </h1>

                    <h1 className='home-special-paragraph'>
                        Obtain gaming license in Western Sahara with only <strong>5,000.00 USD</strong>
                    </h1>
                    
                </div>
            </section>


            {/* HOME FIGURE */}
            <section className='section'>
                <div className='hf-container container'>

                    <h1 className='hf-title section_title'>
                        Your offshore zone solution
                    </h1>

                    <p className='secondary-body-pargraph'>
                        Our clients at Offshore Advisory include businesses from a range of industries, specifically IT
                        companies, payment and banking entities, gambling organizations, Forex initiatives, and
                        cryptocurrency firms.
                    </p>
                    <p className='secondary-body-pargraph'>
                        Additionally, our clients have the option to select from an extensive array of pre-established
                        companies across various commercial sectors, with our experts providing assistance in their
                        acquisition.
                    </p>

                    <p className='secondary-body-pargraph'>
                        Offshore advisory has been an ally for thousands of customers since its inception. We work all
                        over the globe, accompanying banking and payment establishments, international organizations
                        engaged in trade, exchanges working with cryptocurrencies, digital-technology organizations,
                        outsourcing and logistics firms, gambling, IT-structures and many others.
                    </p>

                    <p className='secondary-body-pargraph'>
                        Our specialists know all the legal nuances and law features of the states they work with, which
                        allows us to provide the most effective result for our customers.
                    </p>
                </div>
            </section>

            <section>
                <div className='hf-container container'>
                    <p className='secondary-body-pargraph'>
                        Our clients at Offshore Advisory include businesses from a range of industries, specifically IT
                        companies, payment and banking entities, gambling organizations, Forex initiatives, and
                        cryptocurrency firms.
                    </p>
                    <p className='secondary-body-pargraph'>
                        Additionally, our clients have the option to select from an extensive array of pre-established
                        companies across various commercial sectors, with our experts providing assistance in their
                        acquisition.
                    </p>
                    <p className='secondary-body-pargraph'>
                        Offshore advisory has been an ally for thousands of customers since its inception. We work all
                        over the globe, accompanying banking and payment establishments, international organizations
                        engaged in trade, exchanges working with cryptocurrencies, digital-technology organizations,
                        outsourcing and logistics firms, gambling, IT-structures and many others.
                    </p>
                    <p className='secondary-body-pargraph'>
                        Our specialists know all the legal nuances and law features of the states they work with, which
                        allows us to provide the most effective result for our customers.
                    </p>

                    <p className='secondary-body-pargraph'>
                        Offshore Advisory is a premier provider of corporate, trust, fiduciary, and fund services,
                        offering specialized solutions for a global clientele. We serve international corporations,
                        institutional investors, high-net-worth individuals, families, and intermediaries. Our services
                        span over 80 countries, ensuring compliance and efficiency.
                    </p>
                </div>
            </section>

            <section className='container'>
                <div className='hb-container wrapper-two '>
                    <div className='hb-content'>
                        <img src={VectorSeven} alt='bs-img' className='secondary-vector'/>
                        <h1 className='section_title'>
                            Businesses for sale
                        </h1>
                    </div>

                    <p className='hb-description'>
                        We offer a variety of licenses for sale and are ready to accommodate your business structure
                        while making a great offer tailored to your wishes.
                    </p>

                    <div className='hb-details'>

                        <div className='hb-columns'>
                            <h2 className='hb-column-title'>
                                Ready-made licenses for sale
                            </h2>
                            <ul className='hb-column'>
                                <li className='hb-column-item'>EMI License</li>
                                <li className='hb-column-item'>Ready-made company – forex broker in
                                    <br/>
                                    Saint Vincent and the Grenadines
                                </li>
                                <li className='hb-column-item'>Gambling license</li>
                                <li className='hb-column-item'>Online bookmaker license in Cyprus</li>
                                <li className='hb-column-item'>For sale ready MT5 license – forex broker</li>
                                <li className='hb-column-item'>Financial license</li>
                                <li className='hb-column-item'>Forex broker license</li>
                                <li className='hb-column-item'>Fully licensed EMI UK company</li>
                                <li className='hb-column-item'>Crypto Exchange & Wallet License For Sale</li>
                                <li className='hb-column-item'>For sale beer retail license</li>
                                <li className='hb-column-item'>Ready AEMI in UK for sale</li>
                                <li className='hb-column-item'>License for a cryptocurrency company</li>
                            </ul>
                        </div>

                        <div className='hb-columns'>
                            <h2 className='hb-column-title'>
                                Ready-made companies for sale
                            </h2>
                            <ul className='hb-column'>
                                <li className='hb-column-item'>UK AEMI holding in London for sale</li>
                                <li className='hb-column-item'>Accounting</li>
                                <li className='hb-column-item'>Management Company in Cyprus</li>
                                <li className='hb-column-item'>Ready-made company in Cyprus with an account</li>
                                <li className='hb-column-item'>Ready-made company in Comoros</li>
                                <li className='hb-column-item'>Ready-made company with licenses for crypto exchange and
                                    crypto wallet
                                </li>
                                <li className='hb-column-item'>Ready-made company with an account</li>
                                <li className='hb-column-item'>Custom-made company in Mwali with bank account for sale
                                </li>
                                <li className='hb-column-item'>Ready-made company in Western Sahara</li>
                                <li className='hb-column-item'>Bank Technology company for sale</li>
                                <li className='hb-column-item'>Ready-made company in Anjouan</li>
                            </ul>

                        </div>


                    </div>

                </div>
            </section>


        </main>
    );
}

export default Home;
