import React from 'react'
import './BreadCrumbs.css'

const BreadCrumbs = ({ data }) => {
  return (
    <div className='bread-crumbs container'>
      {data.map((item, index) => (
        <div className='bread-crumbs-link' key={index}>
          <a className='link' href={item.link}>
            {item.name}
          </a>
          {index !== data.length - 1 && (
            <span className='bread-crumbs-link-separator'>—</span>
          )}
        </div>
      ))}
    </div>
  )
}

export default BreadCrumbs
