import React from "react";
import './OurServices.css'
import VectorOne from "../../../Assets/Home/vector-1.png";
import VectorTwo from "../../../Assets/Home/vector-2.png";
import VectorThree from "../../../Assets/Home/vector-3.png";
import VectorFour from "../../../Assets/Home/vector-4.png";
import VectorFive from "../../../Assets/Home/vector-5.png";
import VectorSix from "../../../Assets/Home/vector-6.png";


function OurServices () {

    return (
        <section className=' container'>
            <div className='our-service-container  wrapper-two '>
                <div className='home-services-info '>
                    <h1 className='section_title hs-title'>Discover our service</h1>
                    <p className='hs-description'>
                        The international company Offshore Advisory International provides professional services in the
                        field <br/>
                        of international consulting, auditing services, legal and tax services.
                    </p>
                </div>

                <div className='our-service-content'>
                    <div className='hs-column'>
                        <img src={VectorOne} alt='service-vector' className='secondary-vector'/>
                        <div className='hs-column-content'>
                            <h1 className='hs-column-title'>
                                Ready-made companies for sale
                            </h1>
                            <p className='hs-column-description'>
                                Companies with a bank account around the world
                            </p>
                        </div>
                    </div>

                    <div className='hs-column'>
                        <img src={VectorTwo} alt='service-vector' className='secondary-vector'/>
                        <div className='hs-column-content'>
                            <h1 className='hs-column-title'>
                                Banks for sale
                            </h1>
                            <p className='hs-column-description'>
                                Established Banks for sale
                            </p>
                        </div>
                    </div>

                    <div className='hs-column'>
                        <img src={VectorThree} alt='service-vector' className='secondary-vector'/>
                        <div className='hs-column-content'>
                            <h1 className='hs-column-title'>
                                Ready-made licenses for sale
                            </h1>
                            <p className='hs-column-description'>
                                Forex, cryptocurrencies, gambling,<br/> EMI, PSP, SVF, MSO, DLT
                            </p>
                        </div>
                    </div>

                    <div className='hs-column'>
                        <img src={VectorFour} alt='service-vector' className='secondary-vector'/>
                        <div className='hs-column-content'>
                            <h1 className='hs-column-title'>
                                Licensing
                            </h1>
                            <p className='hs-column-description'>
                                Forex licenses, cryptocurrencies, gambling,<br/> EMI, PSP, SVF, MSO, DLT
                            </p>
                        </div>
                    </div>

                    <div className='hs-column'>
                        <img src={VectorFive} alt='service-vector' className='secondary-vector'/>
                        <div className='hs-column-content'>
                            <h1 className='hs-column-title'>
                                Offshore company registration
                            </h1>
                            <p className='hs-column-description'>
                                Expert advice on the acquisition of an offshore company
                            </p>
                        </div>
                    </div>

                    <div className='hs-column'>
                        <img src={VectorSix} alt='service-vector' className='secondary-vector'/>
                        <div className='hs-column-content'>
                            <h1 className='hs-column-title'>
                                Open a bank account
                            </h1>
                            <p className='hs-column-description'>
                                Open bank accounts in more than 120 banks around the world
                            </p>
                        </div>
                    </div>

                </div>

                <div className='hs-row-container'>
                    <h2 className=' hs-rows-heading'>
                        Other Practices
                    </h2>
                    <ul className='hs-rows'>
                        <div className="hs-row-column">
                            <li className="hs-row-description">Accounting services</li>
                            <li className="hs-row-description">Attorney at law</li>
                            <li className="hs-row-description">Corporate services</li>
                            <li className="hs-row-description">Drafting contracts</li>
                            <li className="hs-row-description">FinTech Services</li>
                            <li className="hs-row-description">Forex trading license</li>
                            <li className="hs-row-description">ICO consulting services</li>
                            <li className="hs-row-description">Investment</li>
                            <li className="hs-row-description">Opening an account in the payment system</li>
                            <li className="hs-row-description">Certificates of good standing, incumbency</li>
                            <li className="hs-row-description">Registration of trademarks</li>
                            <li className="hs-row-description">Tax consulting services</li>
                            <li className="hs-row-description">Valuation and business-modeling</li>
                        </div>
                        <div className="hs-row-column" style={{padding: '0 10px;\n'}}>
                            <li className="hs-row-description">Opening a cryptocurrency debit card</li>
                            <li className="hs-row-description">Citizenship</li>
                            <li className="hs-row-description">Ready-made cryptocurrency licenses for sale</li>
                            <li className="hs-row-description">Due diligence</li>
                            <li className="hs-row-description">Fintech Software Development</li>
                            <li className="hs-row-description">Gambling</li>
                            <li className="hs-row-description">International business certification</li>
                            <li className="hs-row-description">Investment in real estate</li>
                            <li className="hs-row-description">Legal Opinion</li>
                            <li className="hs-row-description">Opening merchant account</li>
                            <li className="hs-row-description">Redomicile of companies</li>
                            <li className="hs-row-description">Support IPO</li>
                            <li className="hs-row-description">Trust registration</li>
                        </div>
                    </ul>

                </div>

            </div>
        </section>
    )
}

export default OurServices;

{
    /*

     */
}