import React from 'react'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import verctor4 from '../../../Assets/images/vector-4.png'
import cyprus_01 from '../../../Assets/images/cyprus-01.png'

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Cyprus',
    link: '/cyprus',
  },
]

function Cyprus() {
  return (
      <main className='main'>
        <section className='section'>
          <BreadCrumbs data={BreadCrumbsData}/>

          <div className='container'>
            <div className='page-header'>
              <img src={verctor4} alt='icon' className='primary-vector'/>
              <h1 className='section_title'>Cyprus</h1>
            </div>
            <div className='head-section'>
              <div className='content'>
                <h2 className='text-left'>Establishing Excellence in Offshore Services</h2>
                <p className='body-pargraph'>
                  Cyprus, strategically located at the crossroads of Europe, Asia, and
                  Africa, has emerged as a premier destination for offshore services,
                  investment opportunities, and residency solutions. Governed by a
                  progressive regulatory authority, Cyprus offers a business-friendly
                  environment with a robust legal framework, making it an ideal
                  location for various financial and corporate services.
                </p>
                <br/>
                <p className='body-pargraph'>
                  At Offshore Advisory, we specialize in navigating the intricacies of
                  Cyprus’s regulatory landscape. Our comprehensive suite of services
                  is designed to facilitate seamless business operations, ensure
                  compliance with local regulations, and optimize your investment
                  strategies.
                </p>
              </div>
              <div className='image'>
                <img src={cyprus_01} alt='landscape'/>
              </div>
            </div>

            <br/>

            <div className='flex-start'>
              <h2 className='section_title'>
                We are dedicated to providing expert guidance and support for the
                following services:
              </h2>
              <h3 className='text-left'>Banking Licenses</h3>
              <p className='body-pargraph'>
                Seamlessly obtain licenses to operate banking services in Cyprus,
                leveraging the country’s financial stability and regulatory support.
                Our team assists with the entire licensing process, from application
                preparation to post-approval compliance, ensuring that your banking
                operations are set up for success in this strategic location.
              </p>

              <h3 className='text-left'>Insurance Licenses</h3>
              <p className='body-pargraph'>
                Safeguard your ventures with comprehensive insurance coverage. We
                facilitate the acquisition of insurance licenses for businesses
                operating in Cyprus, ensuring that you meet all regulatory
                requirements and can offer a full range of insurance products to your
                clients. Our expertise covers various types of insurance licenses,
                including life, health, and property insurance.
              </p>
            </div>

            <div className='flex-start'>
              <h2 className='section_title'>Residency and Citizenship</h2>
              <h3 className='text-left'>Residency in Cyprus</h3>
              <p className='body-pargraph'>
                Secure your residency in Cyprus through various investment and
                business opportunities. Cyprus offers a range of residency options,
                including investor visas, entrepreneur visas, and property owner
                visas. Our team guides you through the application process, ensuring
                you meet all the requirements and benefit from the residency
                privileges.
              </p>
              <h3 className='text-left'>Citizenship and Passport</h3>
              <p className='body-pargraph'>
                Cyprus offers an attractive citizenship-by-investment program,
                allowing investors to obtain Cypriot citizenship and a European Union
                passport. Our experts provide advisory services on acquiring
                citizenship through investment, ensuring you meet all necessary
                criteria and complete the process efficiently.
              </p>
            </div>

            <div className='flex-start'>
              <h2 className='section_title'>Investment Opportunities</h2>
              <h3 className='text-left'>Real Estate Investment</h3>
              <p className='body-pargraph'>
                Cyprus’s real estate market is one of the most attractive in the
                Mediterranean region. Invest in premium properties, from residential
                to commercial, and benefit from high returns and capital appreciation.
                Our team provides comprehensive support, from property selection to
                transaction completion.
              </p>
              <h3 className='text-left'>Business Investment</h3>
              <p className='body-pargraph'>
                Cyprus offers numerous opportunities for business investments,
                including setting up free zone companies, mainland companies, and
                offshore entities. Benefit from Cyprus’s tax advantages, strategic
                location, and business-friendly policies. We assist in identifying the
                best investment opportunities and navigating the regulatory
                requirements.
              </p>
              <h3 className='text-left'>Financial Investment</h3>
              <p className='body-pargraph'>
                Explore diverse financial investment options in Cyprus, including
                stocks, bonds, mutual funds, and other financial instruments. Our
                experts provide tailored investment strategies to help you maximize
                returns and achieve your financial goals.
              </p>
            </div>
            <div className='flex-start'>
              <h2 className='section_title'>Benefits of Choosing Cyprus</h2>
              <h3 className='text-left'>Regulatory Advantages</h3>
              <p className='body-pargraph'>
                The regulatory landscape of Cyprus is designed to accommodate
                international businesses, providing a secure and compliant platform
                for financial operations. The country’s regulatory authorities ensure
                that regulations are clear, consistent, and conducive to business
                growth, allowing you to operate with confidence.
              </p>
              <h3 className='text-left'>Tax Efficiency</h3>
              <p className='body-pargraph'>
                Enjoy favorable tax structures that enhance financial efficiency and
                profitability, making Cyprus an attractive choice for businesses
                seeking tax optimization. The jurisdiction offers various tax
                incentives and benefits, enabling you to maximize your financial
                performance and reinvest savings into your business.
              </p>
              <h3 className='text-left'>Global Accessibility</h3>
              <p className='body-pargraph'>
                Strategically located, Cyprus serves as a gateway to Europe, Asia, and
                Africa, facilitating international business expansion. The country’s
                strategic location provides unparalleled access to key markets,
                enhancing your business's reach and growth potential.
              </p>
              <h3 className='text-left'>Legal Stability</h3>
              <p className='body-pargraph'>
                Benefit from a stable legal environment, ensuring a secure foundation
                for your business operations and financial transactions. Cyprus’s
                legal framework is designed to protect businesses and investors,
                providing peace of mind and a reliable basis for long-term planning.
              </p>
              <h3 className='text-left'>Diverse Licensing and Investment Options</h3>
              <p>
                Cyprus offers a range of licenses, including banking, brokerage, IBC,
                insurance, and trust licenses, as well as diverse investment
                opportunities in real estate, business, and financial markets. This
                diversity allows you to choose the best fit for your business model
                and growth strategy.
              </p>
            </div>
          </div>
        </section>

      </main>
  )
}

export default Cyprus
