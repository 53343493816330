import React from 'react';
import './LicenseAnjouan.css'

/* ~~~~ */
import VectorFour from '../../../Assets/images/vector-4.png'
import LicenseAnjouan_01 from '../../../Assets/images/License-Anjouan-img-01.png'
import LicenseAnjouan_02 from '../../../Assets/images/License-Anjouan-img-02.png'

import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Banking License in Anjouan',
        link: '/license',
    },
]

function LicenseAnjouan () {
    return (
        <main className='main'>
            <section
                className='banking-license-section section'
            >
                <BreadCrumbs data={BreadCrumbsData}/>
                <div className='banking-license-container container' >

                    <div className='offshore-title-container flex-start'>
                        <div className='flex-row'>
                            <img src={VectorFour} className='primary-vector' alt='vector'/>
                            <h1 className='section_title'>Banking License in Anjouan</h1>
                        </div>
                    </div>

                    <div className='licensing-container grid-container '>
                        <div className='flex-start'>
                            <p className='offshore-text'>
                                An International banking License ( Class B ) is the only Banking License offered in Anjouan and is an offshore banking license granted, by the Anjouan Offshore Finance Authority. This is a full-unrestricted Banking license, for all Banking services outside of Anjouan, Union of Comoros. It encompasses banking business and investment banking business conducted in all major foreign currencies. Services such as fund administration, custodial services, trusteeship, international portfolio management, money market and other treasury operations may be undertaken by an offshore bank.  Forming a bank is a relatively quick process due to the lack of bureaucracy. Approval can be granted swiftly if the necessary requirements are met. There are no requirements for local offices or staff, although we would suggest that all companies have this facility which can be arranged at a minimal cost.
                            </p>
                        </div>

                        <div className='image-container' >
                            <img  src={LicenseAnjouan_01} alt='offshore-img'/>
                        </div>
                    </div>

                    <img className='middle-img' src={LicenseAnjouan_02} alt='offshore-img'/>

                    <div className='flex-start'>
                        <p className='body-pargraph'>
                            Application for permission for a banking license should be made to the Anjouan Offshore Finance Authority after an IBC is obtained after completing an IFXBG application forms from the Registrar of International Businesses. On top of the KYC and due diligence requirement, the following documents are required:
                        </p>
                        <ol className='oredered-list'>
                            <li>
                                A detailed business plan of the company: Activities of the bank; Countries in which the bank is looking to invest; The percentage of ownership by the shareholders; The details of the ultimate beneficial owners (chart may help if many); Any other useful information to support the application.
                            </li>
                            <li>
                                CVs of all the directors
                            </li>
                            <li>
                                Bank reference for all the beneficial owners
                            </li>
                            <li>
                                Clear certified passport copies of all beneficial owners
                            </li>
                            <li>
                                Proof of addresses (utility bills)
                            </li>
                            <li>
                                Business or legal reference for the beneficial owners
                            </li>
                            <li>
                                Legal doc for the shareholding companies (articles, certificate of incorporation etc)
                            </li>
                            <li>
                                Latest audited financial statements for all shareholding companies (if any)
                            </li>
                            <li>
                                A certificate to prove that the beneficial owners do not have a criminal record
                            </li>
                            <li>
                                Furthermore, all companies with banking licenses are required to submit their annual accounts to the Authority
                            </li>
                        </ol>
                    </div>

                    <div className='flex-start'>
                        <h1 className='text-left'>
                            KYC AND DUE DILIGENCE
                        </h1>
                        <p className='body-pargraph'>
                            A charge is made for each director / shareholder for due diligence (cost is dependent of country of residence), in the event that a client is not accepted for a license this payment is not refundable.
                        </p>
                        <p className='body-pargraph'>
                            The following documents for each of the beneficial owner, director, manager, authorized signatory, shareholder/member of the Entity or agent under power of attorney must be submitted.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h2>
                            (a) For Individual
                        </h2>
                        <p className='body-pargraph'>
                            A certified copy of passport or current driving license or national identity card showing
                            the name, date and place of birth, nationality, signature of holder and data of
                            issue/expiry. The document must be certified by a banker, lawyer or actuary or notary or
                            accountant holding a recognized professional qualification. Proof of residential address.
                            Acceptable evidence includes an original utility bill, bank or credit card statement.
                            Original Bank Reference.
                        </p>
                        <h2>
                            (b) For Corporate/Partnership
                        </h2>
                        <p className='body-pargraph'>
                            Certificate of incorporation or registration and the memorandum and articles of association or constitution or deed of partnership or Act de Société as the case may be. Document has to be certified by banker, lawyer or actuary or notary or accountant holding a recognized professional qualification. Original Bank Reference.
                        </p>
                        <ul className='license-list'>
                            <li>
                                A certified true copy of latest audited account or annual report.
                            </li>
                            <li>
                                A Certificate of good standing (for Société).
                            </li>
                            <li>
                                List of substantial shareholders (who holds not less than 5% of the voting power exercisable at the meeting of shareholder/member) and a list of directors, and corporate structure.
                            </li>
                            <li>
                                A certified copy of Board resolution granting authority to its managers, officers or employees to transact business on its behalf.
                            </li>
                        </ul>

                    </div>
                </div>
            </section>
        </main>
    )
        ;
}

export default LicenseAnjouan;
