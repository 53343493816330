import React from 'react';
import './OpeningBankAccounts.css'

/* ~~~~ */
import VectorEleven from '../../../Assets/images/vector-11.png'
import Img6 from '../../../Assets/images/img-6.png'
import Img5 from '../../../Assets/images/img-5.png'
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Opening bank accounts',
        link: '/opening-bank-accounts',
    },
]

function OpeningBankAccounts () {
    return (
        <main className='main'>
            <section className='banking-license-section section'>
                <BreadCrumbs data={BreadCrumbsData}/>

                <div className='banking-license-container container '>
                    <div className='offshore-title-container flex-start'>
                        <div className='flex-row'>
                            <img src={VectorEleven} className='primary-vector' alt='vector'/>
                            <h1 className='section_title'>Opening bank accounts</h1>
                        </div>
                    </div>

                    <div className='licensing-container grid-container'>
                        <div className='flex-start'>

                            <p className='body-paragraph'>Requirements for banking systems are increasingly stringent,
                                prompting several countries
                                to implement automatic financial and tax information exchanges. This regulatory
                                tightening aims to monitor citizen cash flows more closely, thus necessitating stricter
                                standards for company documentation.
                            </p>


                            <p className='body-paragraph'>
                                Our company excels in providing efficient and cost-effective services for opening
                                offshore bank accounts. With extensive experience and a network of global banking
                                partners, we streamline the entire process for your business.
                            </p>
                            <br/>

                            <div className='flex-start'>
                                <h1 className='text-left'>
                                    Benefits of an offshore Account include:
                                </h1>

                                <ul className='license-list'>
                                    <li>
                                        Enhanced privacy.
                                    </li>
                                    <li>
                                        Attractive investment opportunities.
                                    </li>
                                    <li>
                                        High reliability.
                                    </li>
                                    <li>
                                        Legal guarantees for fund retrieval in case of bank insolvency.
                                    </li>
                                    <li>
                                        Reduced political risks.
                                    </li>
                                    <li>
                                        Freedom in fund management.
                                    </li>
                                    <li>
                                        Low or absent tax rates.
                                    </li>

                                </ul>
                            </div>

                        </div>

                        <div className='image-container'>
                            <img className='license-img-one' src={Img5} alt='offshore-img'/>
                        </div>
                    </div>

                    <img className='middle-img' src={Img6} alt='offshore-img'/>

                    <div className='flex-start'>
                        <h1 className='text-left'>
                            Types of bank accounts:
                        </h1>

                        <ul className='license-list'>
                            <li>
                                Commercial accounts: Ideal for active businesses like import/export or online
                                consulting, though they require more rigorous maintenance and compliance with laws,
                                attracting minimal transaction fees which might not be preferable for banks.
                            </li>
                            <li>
                                Private banking with personal capital: Suitable for non-commercial transactions, with
                                funds deposited all at once. This includes minimal operations, possibly needing a credit
                                card for personal expenses, aiming for a personalized banking relationship.
                            </li>
                            <li>
                                Offshore retail accounts: Perfect for entrepreneurs testing offshore waters without the
                                intention of extensive transactions or large deposits. These can be opened by
                                individuals or legal entities.
                            </li>
                        </ul>
                        <p className='body-paragraph'>
                            Our experts provide comprehensive financial assistance and offshore account opening
                            services. We also specialize in company formation and licensing, including sectors like
                            cryptocurrency and gaming.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h1 className='text-left'>
                            Steps to open an offshore bank account:
                        </h1>

                        <ol className='oredered-list'>
                            <li>
                                Prepare essential documentation including company constitution and owner identities.
                            </li>
                            <li>
                                Consult with our managers to choose the optimal bank.
                            </li>
                            <li>
                                Submit a preliminary request to the bank, awaiting feedback.
                            </li>
                            <li>
                                Complete and submit required forms post-preliminary approval.
                            </li>
                            <li>
                                Account setup and management initiation.
                            </li>
                            <li>
                                Some banks might require the presence of the director during the process.
                            </li>
                        </ol>
                        <p className='body-paragraph'>
                            Our service fees are for assisting in the account opening, but account approval ultimately
                            depends on the bank's/EMI's review of submitted documents and responses.
                        </p>
                    </div>
                    <div className='flex-start'>
                        <h1 className='text-left'>
                            Documents required typically include:
                        </h1>

                        <ul className='license-list'>
                            <li>
                                Apostilled company documents and proof of officials’ authority.
                            </li>
                            <li>
                                Company reputation proof, if available.
                            </li>
                            <li>
                                Licenses and proof of a physical company office.
                            </li>
                            <li>
                                Director’s notarized passport and recent utility bills.
                            </li>
                        </ul>
                        <p className='body-paragraph'>
                            Our service fees are for assisting in the account opening, but account approval ultimately
                            depends on the bank's/EMI's review of submitted documents and responses.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h1 className='text-left'>
                            Opening an account in an EMI-system offers:
                        </h1>

                        <ul className='license-list'>
                            <li>
                                Faster transactions.
                            </li>
                            <li>
                                Enhanced security and data protection.
                            </li>
                            <li>
                                Remote operation convenience.
                            </li>
                            <li>
                                Lower maintenance and transaction fees.
                            </li>
                            <li>
                                Anytime, anywhere account access.
                            </li>
                        </ul>
                        <p className='body-paragraph'>
                            <strong>How to open an offshore bank account online:</strong><br/>
                            Contact a bank representative, prepare, and submit necessary documents in digital format.
                            Our experts provide remote, professional support to ensure the highest quality service.

                        </p>
                        <p className='body-paragraph'>
                            <strong>What are offshore banking services?</strong><br/>
                            Banking in offshore institutions is known for low taxes, high reliability, and enhanced confidentiality. We are committed to offering the best services for opening offshore accounts.
                        </p>
                    </div>

                </div>
            </section>

        </main>
    );
}

export default OpeningBankAccounts;
