import React from 'react';
import './BankingLicense.css'

/* ~~~~ */
import VectorEleven from '../../../Assets/images/vector-11.png'
import BankingLicense_01 from '../../../Assets/images/banking-license-img-01.png'
import BankingLicense_02 from '../../../Assets/images/banking-license-img-02.png'
import BankingLicense_03 from '../../../Assets/images/banking-license-img-03.png'

import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'Banking License',
        link: '/banking-license',
    },
]

function LicenseMwali () {
    return (
        <main className='main'>
            <section className='banking-license-section section'>
                <BreadCrumbs data={BreadCrumbsData}/>

                <div className='banking-license-container container'>

                    <div className='offshore-title-container flex-start'>
                        <div className='flex-row'>
                            <img src={VectorEleven} className='primary-vector' alt='vector'/>
                            <h1 className='section_title'>
                                Banking License
                            </h1>
                        </div>
                    </div>

                    <div className='licensing-container grid-container'>
                        <div className='flex-start'>

                            <p className='body-paragraph'>
                                Are you looking to establish a bank for international transactions, wealth management,
                                or other financial services? We provide comprehensive support to navigate the licensing
                                process seamlessly. Our team of experts is dedicated to ensuring a smooth transition
                                from application to operation, with a strong focus on regulatory compliance and
                                operational efficiency. Our end-to-end service covers every aspect of banking license
                                acquisition, from initial consultation to post-licensing support.
                            </p>
                            <br/>
                            <h1 className='section_title'>
                                Why Obtain a Banking License Offshore?
                            </h1>
                            <h3 className='text-left'>
                                Regulatory Flexibility
                            </h3>
                            <p className='body-paragraph'>
                                Offshore jurisdictions often have streamlined regulatory frameworks, making it easier to
                                obtain and maintain a banking license compared to more stringent onshore jurisdictions.
                                This regulatory flexibility allows for quicker setup and the ability to adapt swiftly to
                                changing business conditions. Offshore regulatory bodies are typically more open to
                                innovative financial products and services, providing a fertile ground for growth and
                                innovation.
                            </p>

                            <h3 className='text-left'>
                                Tax Optimization
                            </h3>
                            <p className='body-pargraph'>
                                Offshore banks may benefit from favorable tax regimes, allowing for greater efficiency
                                in tax planning and optimization. These jurisdictions often offer lower tax rates or
                                even tax exemptions on certain types of income, enabling your bank to achieve
                                significant cost savings. By strategically planning your tax obligations, you can
                                enhance profitability and reinvest savings into expanding your services and client base.
                            </p>

                            <h2 className='text-left'>
                                Confidentiality
                            </h2>
                            <p className='body-pargraph'>
                                Many offshore jurisdictions prioritize client confidentiality, providing a secure
                                environment for banking operations and client transactions. This high level of privacy
                                helps protect client information from unauthorized access and potential threats. The
                                commitment to confidentiality fosters trust and reliability, attracting clients who
                                value discretion and security in their financial dealings.
                            </p>

                            <h2 className='text-left'>
                                Global Reach
                            </h2>
                            <p className='body-pargraph'>
                                By establishing an offshore bank, you gain access to international markets and can cater
                                to clients from around the world. This global reach enhances your business opportunities
                                and revenue potential, positioning your bank as a key player in the international
                                financial landscape. Offshore banks can offer services in multiple currencies and
                                facilitate cross-border transactions with ease, expanding your customer base and service
                                offerings.
                            </p>
                        </div>

                        <div className='image-container'>
                            <img className='BankingLicense_01' src={BankingLicense_01} alt='offshore-img'/>
                        </div>
                    </div>

                    <div className='flex-wrap'>
                        <img className='banking-lincese-img' src={BankingLicense_02} alt='offshore-img'/>
                        <img className='banking-lincese-img' src={BankingLicense_03} alt='offshore-img'/>
                    </div>
                    <br/>

                    <div className='flex-start'>
                        <h1 className='section_title'>
                            Key Jurisdictions for Banking License Acquisition
                        </h1>

                        <h2 className='text-left'>
                            Western Sahara
                        </h2>

                        <p className='body-paragraph'>
                            Despite its unique status, Western Sahara offers opportunities for banking license
                            acquisition with its favorable regulatory framework and strategic location. The region's
                            emerging financial sector provides a unique chance for early movers to establish a strong
                            presence. With a focus on development and international trade, Western Sahara presents a
                            promising environment for new banking ventures.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h2 className='text-left'>
                            Anjouan
                        </h2>

                        <p className='body-paragraph'>
                            With its robust financial services sector and business-friendly policies, Saint Vincent and
                            the Grenadines provides an ideal setting for banking license acquisition. Its
                            well-established legal framework supports a variety of banking activities and services. The
                            jurisdiction’s reputation for stability and transparency attracts businesses seeking a
                            reliable and reputable base for their banking operations.
                        </p>
                    </div>
                    <div className='flex-start'>
                        <h2 className='text-left'>
                            Comoros
                        </h2>

                        <p className='body-paragraph'>
                            Comoros, situated in the Indian Ocean, offers a conducive environment for offshore banking
                            operations, with simplified licensing procedures and attractive incentives. The country’s
                            strategic position and growing financial infrastructure make it a promising location for new
                            banking ventures. Comoros provides a business-friendly atmosphere with a focus on
                            facilitating international banking activities and investments.
                        </p>
                    </div>

                    <div className='flex-start'>
                        <h1 className='section_title'>
                            Our Comprehensive Support Services
                        </h1>

                        <h2 className='text-left'>
                            Consultation and Strategy Development
                        </h2>
                        <p className='body-paragraph'>
                            We start by understanding your business goals and objectives, and then we develop a tailored
                            strategy for acquiring your banking license. Our experts provide insights into the best
                            jurisdictions for your specific needs and guide you through the regulatory requirements.
                        </p>
                        <h3 className='text-left'>
                            Application Preparation and Submission
                        </h3>
                        <p className='body-pargraph'>
                            Our team assists in preparing all necessary documentation and ensures that your application
                            meets the specific requirements of the chosen jurisdiction. We handle the submission process
                            and liaise with regulatory authorities on your behalf.
                        </p>
                        <h2 className='text-left'>
                            Post-Licensing Support
                        </h2>
                        <p className='body-pargraph'>
                            After obtaining your banking license, we offer ongoing support to ensure your bank remains
                            compliant with all regulatory requirements. Our services include compliance monitoring,
                            reporting, and updates on any changes in regulations.
                        </p>
                    </div>

                </div>
            </section>


        </main>
    );
}

export default LicenseMwali;
