import React from 'react';
import './Contact.css';
import {FaLinkedin, FaTelegramPlane} from "react-icons/fa";
import { FaSkype } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp } from "react-icons/io";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";


const Contact = () => {

    const cyprusTelegram = '+35796782975';
    const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

    const cyprusWhatsappUrl = `https://wa.me/${cyprusTelegram.replace('+', '')}`;



    const BreadCrumbsData = [
        { name: 'Offshore Advisory', link: '/' },
        {
            name: 'Contact us',
            link: '/contact',
        },
    ]

    return (
        <main className='main'>

            <section className='section '>


                <div className='contact-details container'>

                    <div className='flex-column'>
                        <BreadCrumbs  data={BreadCrumbsData}/>

                        <h1 className='section_title'>
                            Contact us
                        </h1>
                    </div>

                    <div className='flex-start '>
                        <div className='contact-social-container'>
                            We are in social networks:
                            <div className='contact-social-buttons'>
                                <a href='https://join.skype.com/invite/AdDbThJOeejF' target='_blank' >
                                    <FaSkype  className='contact-social-icon'/>
                                </a>
                                <a href='https://www.facebook.com/share/vC5S5JbubCZ47WRx/?mibextid=LQQJ4d'
                                   target='_blank'>
                                    <FaFacebook className='contact-social-icon'/>
                                </a>
                                <a href={cyprusTelegramUrl} target='_blank' >
                                    <FaTelegramPlane  className='contact-social-icon'/>
                                </a>
                                <a href='/'>
                                    <FaLinkedin className='contact-social-icon'/>
                                </a>
                                <a href={cyprusWhatsappUrl} target='_blank' >
                                    <IoLogoWhatsapp  className='contact-social-icon'/>
                                </a>
                            </div>
                        </div>


                        <div className='contact-email-container'>
                            <span><strong>E-mail: </strong></span>
                            <a href='mailto:info@offshoreadvisory.pro' className='contact-email-link'>
                                info@offshoreadvisory.pro
                            </a>
                        </div>
                    </div>


                </div>

            </section>
        </main>
    );
}

export default Contact;
