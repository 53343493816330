import React from 'react'
import BreadCrumbs from '../BreadCrumbs/BreadCrumbs'
import verctor6 from '../../../Assets/Home/vector-6.png'
import GamingAndGamblingWesternSahara_01 from '../../../Assets/images/gaming-and-gambling-western-sahara-01.png'
import GamingAndGamblingWesternSahara_02 from '../../../Assets/images/gaming-and-gambling-western-sahara-02.png'

const BreadCrumbsData = [
  { name: 'Offshore Advisory', link: '/' },
  {
    name: 'Gaming and Gambling In Western Sahara',
    link: '/gaming-and-gambling-western-sahara',
  },
]

function GamingAndGamblingWesternSahara() {
  return (
    <main className='main'>
      <section className='section'>
        <BreadCrumbs data={BreadCrumbsData} />

        <div className='container'>

          <div className='page-header'>
            <img src={verctor6} alt='icon' className='primary-vector' />
            <h1 className='section_title'>Gaming and Gambling license in Western Sahara</h1>
          </div>
          <br/>

          <div className='head-section'>
            <div className='content'>
              <p className='body-pargraph'>
                Western Sahara provides different banking and financial services
                in the jurisdiction of SADR, including eGambling license. There
                are two categories of eGambling license:
              </p>
              <p className='body-pargraph'>
                License authorizes the organization and preparation of gambling
                operations, namely the registration and verification of players,
                the contractual relationship with them, and the management of
                player funds.
              </p>
              <p className='body-pargraph'>
                License authorizes the effecting of the gambling transaction
                including operational management of a gambling platform located
                within an approved hosting center.
              </p>
              <p className='body-pargraph'>
                Depending on their proposed operations, an organization may
                apply for one or both types of license.
              </p>
            </div>
            <div className='image'>
              <img src={GamingAndGamblingWesternSahara_01} alt='landscape' />
            </div>
          </div>
          <div className='vector-img'>
            <img src={GamingAndGamblingWesternSahara_02} alt='due diligence' />
          </div>
          <div className='body-content'>
            <h2>Time frame</h2>
            <h3>1 day</h3>
            <p>Booking the name of the company-1 day</p>
            <br/>

            <h3>2-3 weeks</h3>
            <p>
              eGambling license approval upon receipt of application fee,
              approved application form and KYC documents (scans ok to
              initiate)-2-3 weeks
            </p>
            <br/>
            <h3>2 days</h3>
            <p className='body-pargraph'>
              When the funds are received and confirmed, the CRA of SADR may
              take 2 days to register and notarise the license.
            </p>
            <br/>

            <h2 className='text-left'>FAQ about eGambling license</h2>
            <p className='body-pargraph'>
              <h3>Are there other costs to be expected?</h3>
              <p>
                No it takes 3 weeks to create the company and obtain the online
                gambling license
              </p>
              <p>Do we need to provide proof of accounting?</p>
              <p>Not applicable</p>
              <p>
                What are the tax rules in Western Sahara? Are there any taxes?
              </p>
              <p>No taxes</p>
              <p>Should we need a company secretary?</p>
              <p>No</p>
              <p>is there a legal minimum share capital?</p>
              <p>The minimum capital is USD 100,000.00</p>
              <p>
                Should the company open a bank account in a western saharian bank
              </p>
              <p>You need to open a bank account outside of Western Sahara.</p>
            </p>

            <h2 className='text-left'>Application form and documents</h2>
            <br/>
            <p>Signed application form;</p>
            <br/>
            <p>
              Personal info including name, address, phone number and email of
              all directors/shareholders;
            </p>
            <br/>
            <p>Bank referense;</p>
            <br/>
            <p>Business reference;</p>
            <br/>
            <p>ID of all directors/shareholders;</p>
            <br/>
            <p>
              It is required for the business owner, the Board member, the real
              beneficiary or the proprietar to submit a statement from the
              penalty register in one's country of origin or an equivalent
              document issued by a judicial or administrative authority
              confirming the absence of penalties for crimes committed against
              government authorities, money laundering crimes or another
              deliberate crimes no earlier than three months ago and certified
              by a notary or in another similar way and legalised or approved by
              the certificate replacing legalisation (Apostille), unless
              otherwise provided by an international treaty.
            </p>
          </div>
        </div>
      </section>
    </main>
  )
}

export default GamingAndGamblingWesternSahara
