import React from 'react';
import './About.css'

/* ~~~~ */

import AboutVector_12 from '../../../Assets/images/vector-12.png';
import AboutVector_13 from '../../../Assets/images/vector-13.png';
import AboutVector_14 from '../../../Assets/images/vector-14.png';
import AboutVector_15 from '../../../Assets/images/vector-15.png';
import AboutVector_16 from '../../../Assets/images/vector-16.png';
import AboutVectorOne from '../../../Assets/images/about-vector-1.png';
import Shape_01 from '../../../Assets/images/shape-01.png';


import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";

const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'About the Company',
        link: '/about',
    },
]

function About() {
    return (
        <main className='main'>
            <section
                className='about-section section'
            >
                <BreadCrumbs data={BreadCrumbsData}/>

                <div className="about-container container">

                    <div className='about-header'>
                        <h1 className="section_title">
                            About the Company
                        </h1>
                        <p className='about-description'>
                            Offshore Advisory provides professional consulting, legal, tax and
                            auditing services.
                        </p>
                        <span className='body-pargraph'>
                        Our specialists provide the following services globally.
                        </span>
                    </div>

                    <div className='about-columns'>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon' />
                            <p className='about-column-descrioption'>
                                Registration and Planning for International Business
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                International Tax Advisory
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            International Consulting services
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Accounting and Financial Reporting Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Evaluation and Business Modelling
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                Tax Policy and Tax Dispute Resolution Advice
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Transfer Pricing Services
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Strategic Advice for Growth Markets
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Transaction Advisory Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                Advice on Restructuring
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                Asset Sale Advisory Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                Real
                                Estate Consulting Service
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Support for Mergers and Acquisitions
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                Services for the operational integration of transactions;
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                                Increase business efficiency;
                            </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Management and Information Technology</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Consulting Services for the Financial Sector</p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Business Risk Management Advice</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Services for Clean Technology and Sustainable
                                Development</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Financial Reporting Advisory Services (FAAS)</p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Investigation of Fraud and Conflict Resolution
                                Advice</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Audit
                                Services</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Asset
                                Protection Service</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Accompanying the opening of bank accounts and
                                acquiring;</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Guidance and Support to Obtain Licenses for
                                Gambling
                                Activities</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Guidance and Support to Obtain Licenses for Forex
                                Activities</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p
                                className='about-column-descrioption'>Facilitate Ownership of Financial Licenses</p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Support
                                in Obtaining Licenses for Cryptocurrencies and ICO</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Support
                                of mergers and acquisitions (MNA)</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Registration of Aircraft and Marine Craft</p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>
                            Provision of Virtual Office Services
                            </p></div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Obtain
                                and Establish Trademarks, Logo and Marks </p>
                        </div>
                        <div className='about-column'>
                            <img src={Shape_01} alt='shape-01' className='primary-icon'/>
                            <p className='about-column-descrioption'>Design
                                and Protection of Intellectual Property
                            </p>
                        </div>
                    </div>
                </div>

                <div className='container wrapper-block'>
                    <h1 className='text-left'>We work adhering to the principles:</h1>
                    <br/>
                    <div className='about-figure flex-wrap'>
                        <div className='about-figure-content flex-column'>
                            <img src={AboutVector_12} alt='service-vector' className='vector-primary'/>
                            <h3 className='about-figure-title'>Confidentiality</h3>
                        </div>
                        <div className='about-figure-content flex-column'>
                            <img src={AboutVector_13} alt='service-vector' className='vector-primary'/>
                            <h3 className='about-figure-title'>Data protection</h3>
                        </div>
                        <div className='about-figure-content flex-column'>
                            <img src={AboutVector_14} alt='service-vector' className='vector-primary'/>
                            <h3 className='about-figure-title'>Legality</h3>
                        </div>
                        <div className='about-figure-content flex-column'>
                            <img src={AboutVector_15} alt='service-vector' className='vector-primary'/>
                            <h3 className='about-figure-title'>
                                Individual approach to the client
                            </h3>
                        </div>
                        <div className='about-figure-content flex-column'>
                            <img src={AboutVector_16} alt='service-vector' className='vector-primary'/>
                            <h3 className='about-figure-title'>
                                Completion of tasks
                                <br/>
                                within the agreed time
                                frame
                            </h3>
                        </div>

                    </div>
                </div>

                <div className='about-tax-grid container padding-block'>
                    <div className='about-tax-content'>
                        <div className='about-main-tasks about-grid-item'>
                            <h1 className='text-left '>OUR MAIN TASKS:</h1>
                            <p className='body-pargraph'>
                                Help clients reduce tax liabilities through legal methods.<br/>
                                Safeguard client assets from potential risks.<br/>
                                Offer informed advice derived from an in-depth review of the state’s legal system to
                                improve
                                client financial outcomes.<br/>
                                Provide assistance with pre-trial or court settlements of corporate disputes across
                                various
                                jurisdictions.<br/>
                                Maintain support for client businesses throughout all phases of their operations.<br/>
                            </p>
                        </div>

                        <div className='about-specialists '>
                            <h1 className=' text-left '>OUR SPECIALISTS:</h1>
                            <p className='body-pargraph'>
                                Provide customized solutions based on the specific situation;<br/>
                                Offer customers only legally sound and optimal solutions for a specific situation;<br/>
                                Warn the client in advance about the possible consequences of the decisions made;<br/>
                                Keep customer data confidential;<br/>
                                We act strictly in accordance with the rules of attorney ethics;<br/>
                                We are constantly improving the knowledge and skills of applying Ukrainian legislation
                                and a
                                number of laws of other countries.<br/>
                            </p>
                        </div>

                        <div className='about-advantages about-grid-item'>
                            <h1 className='text-left '>WHAT ARE OUR ADVANTAGES:</h1>
                            <p className='body-pargraph'>
                                The presence of lawyers in our company provides additional guarantees of our
                                professionalism, as well as<br/>
                                guarantees of confidentiality, because lawyer secrecy applies to any information that a
                                client conveys about himself
                                or his activities, information in any form is not subject to disclosure to third parties
                                without the client’s consent;<br/>
                                Our company has a unique experience and knowledge of the specifics of work in most
                                countries
                                around the world <br/>
                                We develop solutions based on the analysis of legislation and look to the future,
                                studying
                                possible changes in the laws of specific states.<br/>
                                Customer safety is most important to us;<br/>
                                We value every customer <br/>
                            </p>
                        </div>
                    </div>

                    <img src={AboutVectorOne} alt='about-vector-one' className='about-vector-one'/>
                </div>


            </section>


        </main>
    );
}

export default About;
