import React from 'react';

/* ~~~~ */
import { FaTelegramPlane } from "react-icons/fa";
import { FaLinkedin } from "react-icons/fa";
import BreadCrumbs from "../BreadCrumbs/BreadCrumbs";


const BreadCrumbsData = [
    { name: 'Offshore Advisory', link: '/' },
    {
        name: 'FAQ',
        link: '/faq',
    },
]

function FAQ () {

    const cyprusTelegram = '+35796782975';
    const cyprusTelegramUrl = `https://t.me/${cyprusTelegram}`;

    return (
        <main className='main'>
            <section
                className='faq-section section'
            >
                <div className='container '>

                    <div className='flex-column'>
                        <BreadCrumbs data={BreadCrumbsData}/>

                        <h1 className='section_title'>
                            Frequently Asked Questions
                        </h1>
                        <br/>
                        <div className='flex-row'>
                            <span>Share it: </span>
                            <a href={cyprusTelegramUrl} className='contact-info-link'>
                                <FaTelegramPlane/>
                            </a>

                            <FaLinkedin className='icon'/>

                        </div>
                    </div>
                    <article className='faq-detail'>
                        <h1 className='text-left'>
                            What is Offshore Advisory?
                        </h1>
                        <p className=''>
                            Offshore Advisory is a firm that provides a variety of services related to international
                            business, including company formation, opening bank accounts, obtaining licenses and
                            permits, as well as other legal and financial services. According to its website, the
                            company has offices in multiple countries and offers support to clients looking to start or
                            expand their businesses internationally.
                        </p>
                    </article>

                    <article className='faq-detail'>
                        <h1 className='text-left'>
                            What are licensed institutions?
                        </h1>
                        <p className=''>
                            Institutions authorized to hold ready-made licenses can differ based on the license type and
                            the issuing country. Typically, licenses are granted by governmental authorities or
                            regulatory bodies within each jurisdiction, which also set the criteria and rules for
                            acquiring and transferring licenses. It’s crucial to understand that purchasing a ready-made
                            license should not be impulsive, as it might not suit specific business needs or be
                            transferable across jurisdictions. Thus, consulting with a licensed professional and
                            conducting detailed research before purchasing any ready-made license is recommended.
                        </p>
                    </article>

                    <article className='faq-detail'>
                        <h1 className='text-left'>
                            Who is the team of Offshore Advisory?
                        </h1>
                        <p className=''>
                            Offshore Advisory boasts a team of seasoned experts in international business, corporate
                            law, tax planning, and financial services. This team includes lawyers, IT developers,
                            compliance specialists, and other professionals proficient in their fields, capable of
                            delivering comprehensive solutions to clients.
                        </p>
                    </article>
                    <article className='faq-detail'>
                        <h1 className='text-left'>
                            How long has Offshore Advisory been operational?
                        </h1>
                        <p className=''>
                            Offshore Advisory was established in 2018. As of 2024, they have been active in the market
                            for six years, earning a stellar reputation and positive feedback from clients.
                        </p>
                    </article>
                    <article className='faq-detail'>
                        <h1 className='text-left'>
                            Advantages of working with us:
                        </h1>

                        <p className='body-pargraph'>
                                International experience: The team has extensive experience working with clients across
                                various jurisdictions and offers complex solutions tailored to meet specific needs.
                        </p>

                        <p className='body-pargraph'>
                                Full range of services: They provide a comprehensive suite of services related to
                                international business, including company formation, bank account openings, license
                                and permit acquisitions, tax planning, and other necessary legal and financial
                                services.
                        </p>
                        <p className='body-pargraph'>
                                Fast and efficient service: Offshore Advisory aims to deliver prompt and efficient
                                services, typically completing most tasks within 1-2 business days.
                        </p>
                        <p className='body-pargraph'>
                                Competitive pricing: They offer competitive rates for their services, ensuring
                                transparency with no hidden charges.
                        </p>
                        <p className='body-pargraph'>
                                Confidentiality and security: The confidentiality and security of client information
                                are paramount, with robust measures in place to safeguard client data.
                        </p>
                    </article>
                </div>

            </section>
        </main>
    );
}

export default FAQ;
